<!-- 设置 账号管理 -->
<template>
  <el-dialog
      title="编辑用户信息"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="userObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
    >
      <el-form-item label="角色" prop="sysRoleId" style="width: 100%" required>
        <el-select v-model="userObj.sysRoleId" placeholder="请选择角色分类">
          <el-option
              v-for="item in roleList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号" prop="account" style="width: 100%" required>
        <el-input v-model="userObj.account" :disabled="userObj.id > 0">
        </el-input>
      </el-form-item>
      <el-form-item
          label="密码"
          prop="password"
          style="width: 100%"
          v-if="!userObj.id"
      >
        <el-input v-model="userObj.password"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">

export default {
  data () {
    return {
      dialogVisible: false,
      userObj: {},
      roleList: [],
      rules: {
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        account: [
          {
            required: true,
            message: '请输入账号',
            trigger: 'blur'
          }
        ],
        sysRoleId: [
          {
            required: true,
            message: '请选择角色分类',
            trigger: 'change'
          }
        ]
      },
      id: 0
    }
  },

  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
    },
    show () {
      return new Promise(resolve => {
        this.initData()
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          resolve()
        })
      })
    },
    async edit (item) {
      await this.show()
      this.userObj = JSON.parse(JSON.stringify(item))
    },
    initData () {
      this.userObj = {
        account: '',
        password: '',
        sysRoleId: ''
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.userObj.password = this.$rsa(this.userObj.password)
          const {
            id,
            account,
            password,
            sysRoleId
          } = this.userObj
          this.$api.save('sysUser', {
            id,
            account,
            password,
            sysRoleId
          }).then(res => {
            this.close()
            this.$emit('on-submit')
          })
        }
      })
    }
  },
  mounted () {
    this.$api.findAll('sysRole').then(res => {
      this.roleList = res.data
    })
  }
}
</script>
